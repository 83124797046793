import cloneDeep from 'clone-deep';
import { GenericSurveyFilterStoreState, SelectedGenericFilterScale } from '@/interfaces/stores';

export const getDefaultStateGenericSurveyFilters = (): GenericSurveyFilterStoreState => ({
    selectedGenericFilterScales: [],
});

const genericSurveyFilterStore = {
    namespaced: true,
    state: getDefaultStateGenericSurveyFilters(),
    mutations: {
        resetState(state: GenericSurveyFilterStoreState): void {
            Object.assign(state, getDefaultStateGenericSurveyFilters());
        },
        setSelectedFilterScales(state: GenericSurveyFilterStoreState, selectedScales: SelectedGenericFilterScale[]): void {
            state.selectedGenericFilterScales = selectedScales;
        },
        deleteFilterScale(state: GenericSurveyFilterStoreState, index: number): void {
            const filtersClone = cloneDeep(state.selectedGenericFilterScales);
            filtersClone.splice(index, 1);
            state.selectedGenericFilterScales = filtersClone;
        },
    },
    actions: {},
};

export default genericSurveyFilterStore;
