import { getTemplate } from '@/services/templateService';
import { TemplateStoreState } from '@/interfaces/stores';
import {
    defaultTemplate,
} from '@/fixtures/template';

export const getDefaultStateTemplate = (): TemplateStoreState => ({
    template: defaultTemplate,
});

const templateStore = {
    namespaced: true,
    state: getDefaultStateTemplate(),
    getters: {},
    mutations: {
        resetState(state): void {
            Object.assign(state, getDefaultStateTemplate());
        },
        initializeState(state, loadedTemplate): void {
            state.template = loadedTemplate;
        },
    },
    actions: {
        async get({ dispatch, commit }, { templateId }) {
            return getTemplate(templateId).then((template) => {
                commit('initializeState', template.data);
            }).catch((error: Error) => {
                dispatch('errorStore/errorReceived', error, { root: true });
            });
        },
    },
};

export default templateStore;
