import httpClient from '@/clients/httpClient';
import { validateEmpty } from '@/services/utilities';
import { GenericTemplateResponse } from '@/interfaces/genericTemplate';

/*
 * Generic template loader
 */
// eslint-disable-next-line import/prefer-default-export
export const getTemplate = async (templateId: string) => {
    validateEmpty('Template-ID', templateId);
    return httpClient.get(`/api/template/${templateId}`, { params: {} })
        .then((response: GenericTemplateResponse) => response);
};
