<template>
  <v-skeleton-loader
    type="text"
    :loading="isLoading"
    width="200"
  >
    <span
      class="primary--text text-button"
      style="line-height: normal"
      data-name="guidedTourLink"
      @click="emitStartTour()"
    >
      {{ $t('howIsThisCalculated') }}
    </span>
  </v-skeleton-loader>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { GuidedTourLinkInterface } from '@/interfaces/components';

@Component({})
export default class GuidedTourLink extends Vue implements GuidedTourLinkInterface {
    @Prop({ default: true }) isLoading!: boolean;

    emitStartTour(): void {
        this.$emit('startTour');
    }
}
</script>
