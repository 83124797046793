import { ExternalVuetifyTheme } from '@/interfaces/externalVuetifyTheme';

import { basic } from '@/constants/themes/basic';
import { neutralTheme1 } from '@/constants/themes/neutralTheme1';
import { trafficLightTheme } from '@/constants/themes/trafficLight';

export default <Record<string, ExternalVuetifyTheme>> {
    basic,
    neutral: neutralTheme1,
    redGreenDiverging: trafficLightTheme,
};

export const themeSelectorInventory = [
    { text: 'Basic', value: 'basic' },
    { text: 'Neutral', value: 'neutralTheme1' },
];
