// eslint-disable-next-line import/prefer-default-export
export const neutralTheme1 = {
    primary: {
        lighten1: '#E7E8ED',
        base: '#4A4870',
        darken1: '#251F3F',
    },

    secondary: {
        lighten1: '#ECEDEF',
        base: '#5C6E81',
        darken1: '#20272E',
    },

    decorative1: '#B73A89',
    decorative2: '#3A4BB7',

    textPrimary: '#212B36',
    textSecondary: '#5E738A',

    criticalPrimary: '#D83543',
    criticalSecondary: '#FACAD1',

    warningPrimary: '#E3A12B',
    warningSecondary: '#FFF2DA',

    informativePrimary: '#673AB7',
    informativeSecondary: '#D6C8F3',

    successPrimary: '#208234',
    successSecondary: '#EBFFED',

    neutralShade: {
        lighten5: '#F4F6F8',
        lighten4: '#CCD5DE',
        lighten3: '#ADB9C6',
        lighten2: '#8D9DAE',
        lighten1: '#76879B',
        base: '#5E738A',
        darken1: '#516579',
        darken2: '#425263',
        darken3: '#333F4D',
        darken4: '#212B36',
    },

    primaryShade: {
        lighten5: '#E7E8ED', // 50
        lighten4: '#C4C5D3', // 100
        lighten3: '#9EA0B5', // 200
        lighten2: '#7B7C97', // 300
        lighten1: '#616183', // 400
        base: '#4A4870', // 500
        darken1: '#434068', // 600
        darken2: '#3B385D', // 700
        darken3: '#332F51', // 800
        darken4: '#251F3F', // 900
    },
};
