import httpClient from '@/clients/httpClient';
import { validateEmpty, addFiltersToParams } from '@/services/utilities';
import type {
    ResultsAggregatedResponse,
    ResultsRawResponse,
    ResultsResponse,
} from '@/interfaces/results';
import { GenericFilterScaleListItem } from '@/interfaces/components';

const getResultsUrlBase = '/api/results/{instanceId}/descriptive/{componentId}';

function replaceParams(instanceId: string, componentId: string) {
    let url = getResultsUrlBase;
    url = url.replace('{instanceId}', instanceId);
    url = url.replace('{componentId}', componentId);

    return url;
}

export const getDescriptiveResults = async (
    instanceId: string,
    componentId: string,
    selectedFilterScales: GenericFilterScaleListItem[] = [],
): Promise<ResultsResponse> => {
    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Component-ID', componentId);

    const url = replaceParams(instanceId, componentId);

    let params = {};

    if (selectedFilterScales.length) {
        const filterParams = addFiltersToParams(selectedFilterScales);
        params = { ...params, ...filterParams };
    }

    return httpClient.get(url, { params })
        .then((response) => response);
};

export const getDescriptiveResultAggregated = async (
    instanceId: string,
    componentId: string,
    selectedFilterScales: GenericFilterScaleListItem[] = [],
): Promise<ResultsAggregatedResponse> => {
    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Component-ID', componentId);

    const url = replaceParams(instanceId, componentId);

    let params = {};

    if (selectedFilterScales.length) {
        const filterParams = addFiltersToParams(selectedFilterScales);
        params = { ...params, ...filterParams };
    }

    return httpClient.get(`${url}/aggregated`, { params })
        .then((response) => response);
};

export const getDescriptiveResultRaw = async (
    instanceId: string,
    componentId: string,
    selectedFilterScales: GenericFilterScaleListItem[] = [],
): Promise<ResultsRawResponse> => {
    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Component-ID', componentId);

    const url = replaceParams(instanceId, componentId);

    let params = {};

    if (selectedFilterScales.length) {
        const filterParams = addFiltersToParams(selectedFilterScales);
        params = { ...params, ...filterParams };
    }

    return httpClient.get(`${url}/raw`, { params })
        .then((response) => response);
};
