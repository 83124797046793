import defaultTheme from '@/constants/externalThemes';

export const measureFav: string = 'percentFavorable';
export const measureMean: string = 'mean';

export const measurementLevelDefault: string = 'default';
export const measurementLevelNominal: string = 'nominal';
export const measurementLevelOrdinal: string = 'ordinal';

export const devArea: string = 'dev';
export const strengthArea: string = 'strength';
export const allArea: string = 'all';

export const resultTypeNormal = 'normal';
export const resultTypeMissing = 'missing';
export const resultTypeAnonym = 'anonymity';
export const resultTypeSkipped = 'skipped';
export const resultTypeNotSeen = 'notSeen';

export const defaultNeutralChartColor: string = defaultTheme.basic.primaryShade.lighten1;
export const defaultLowScoreColor: string = defaultTheme.basic.primaryShade.darken3;
export const defaultHighScoreColor: string = defaultTheme.basic.primaryShade.lighten3;
export const defaultBackgroundColor: string = '#ffffff';
export const defaultThemeColor: string = defaultTheme.basic.primary.base;
export const defaultThemeName: string = 'basic';
export const divergingThemeName: string = 'redGreenDiverging';

export const defaultLocale: string = 'en_US';
export const defaultRefreshRate: number = 30;
export const defaultMeasure: string = measureMean;
export const defaultShowOverallKpi: boolean = false;
export const defaultShowResources: boolean = false;
export const defaultHistoricalTrend: boolean = false;
export const defaultAnonymityThreshold: number = 5;
export const defaultAnonymityThresholdActivated: boolean = false;

export const splitModeDefault = 'options';
export const splitModeBreakout = 'breakout';

export const heatmapDataTypeCategory = 'category';
export const heatmapDataTypeQuestion = 'question';
export const heatmapDataTypeScale = 'scale';

export const heatmapResultTypeAbsolute = 'absolute';
export const heatmapResultTypeRelative = 'relative';
export const heatmapResultTypePercent = 'percent';

export const actionStatusNone = 'none';
export const actionStatusTakeAction = 'takeAction';
export const actionStatusSeeAction = 'seeAction';

export const heatmapPercentColorStep = 20;

export const raterGroupAll = 'all_sum';
export const raterGroupAllLabel = 'all';
export const raterGroupSelf = 'sys_rg_self_assessment';

export const templateIds = {
    questionDV: 'E0BAB2EF-14F4-4A09-964F-6558DC1CD875',
    categoryDV: '284B5916-2B82-46A8-9E7B-270CC913605A',
    multiRaterDV: 'A4Ac5677-8B70-424A-ACf7-6C1ED24135D8',
    vibeDV: '87D0E892-8998-4450-8D6C-EAC483CA0E0D',
    aggregatedDV: 'A8114601-8BEE-42BA-B1C7-DB9B93B7159A',
    question: '4BA477D0-8B53-11EC-9EEC-BB54F2B5BCA3',
    category: '67AF1976-8B53-11EC-BD73-179E79D69D0A',
    multiRater: '6C5666E6-8B53-11EC-A3A3-A3130991D4A3',
    vibe: '6FE1995C-8B53-11EC-BB5B-BF193BB3318A',
    aggregated: '214480BE-BB24-42D2-8E16-BA2E53995FF0',
    generic: '214480BE-BB24-42D2-8E16-12345',
};

export const instanceTypes = {
    question: 'question',
    category: 'category',
    multiRater: 'multiRater',
    vibe: 'vibe',
    aggregated: 'aggregated',
    generic: 'generic',
};

export const selectAllKeys = 'all';
