import { getDescriptiveResultRaw } from '@/services/resultsService';
import { ResultRawStoreState } from '@/interfaces/stores';

export const getDefaultStateResultsRaw = (): ResultRawStoreState => ({
    results: {},
});

const resultsRawStore = {
    namespaced: true,
    state: getDefaultStateResultsRaw(),
    getters: {},
    mutations: {
        resetState(state): void {
            Object.assign(state, getDefaultStateResultsRaw());
        },
        initializeState(state, loadedResults): void {
            state.results = loadedResults;
        },
    },
    actions: {
        async get({ dispatch, commit, rootState }, { instanceId, componentId }) {
            const selectedFilterScales = rootState.genericSurveyFilters.selectedGenericFilterScales;

            return getDescriptiveResultRaw(instanceId, componentId, selectedFilterScales)
                .then((results) => {
                    commit('initializeState', results.data);
                }).catch((error: Error) => {
                    dispatch('errorStore/errorReceived', error, { root: true });
                });
        },
    },
};

export default resultsRawStore;
