<template>
  <div class="select">
    <v-select
      item-text="label"
      item-value="metaname"
      v-model="value"
      :items="options"
      @change="emitInput"
      outlined
      dense
      hide-details
    />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { SelectInterface } from '@/interfaces/components';
import { OptionItem } from '@/interfaces/visual';

@Component({})
export default class Select extends Vue implements SelectInterface {
    @Prop({ default: [] }) options!: OptionItem[];
    @Prop() value!: string;

    emitInput(newVal: string): void {
        this.$emit('input', newVal);
    }
}
</script>

<style lang="scss">
@import '@/scss/01_base/variables';

.select {
  display: flex;
  max-width: 300px;
  height: 30px;
}

.v-text-field--outlined fieldset {
  border: solid $cardBorderWidth var(--v-neutralShade-lighten4);
  border-radius: $cardBorderRadius;
}
.v-text-field input {
  padding: 0 !important;
}
.v-select.v-input--dense .v-select__selection--comma {
  margin: 0 !important;
  padding: 0 !important;
}
</style>
