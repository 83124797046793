<template>
  <div class="framed-switch">
    <div
      class="framed-switch__option"
      v-for="({ metaname, label }, index) in options"
      :key="`tab_${index}`"
      :class="{ 'framed-switch__option--selected': (metaname === value) }"
      :data-v-step="index === 0 ? linkTourKey : null"
      @click="emitInput(metaname)">
      {{label}}
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { FramedSwitchInterface } from '@/interfaces/components';
import { OptionItem } from '@/interfaces/visual';

@Component({})
export default class FramedSwitch extends Vue implements FramedSwitchInterface {
    @Prop({ default: [] }) options!: OptionItem[];
    @Prop({ default: 0 }) value!: string;
    @Prop({ default: null }) linkTourKey!: string;

    emitInput(value: string): void {
        if (this.value !== value) {
            this.$emit('input', value);
        }
    }
}
</script>

<style lang="scss">
@import '@/scss/01_base/variables';
@import '@/scss/01_base/_mixins.scss';

.framed-switch {
  background: var(--card-background-color);
  border: 1px solid var(--chart-background-color);
  border-radius: $cardBorderRadius;
  display: flex;
  font-size: $defaultMediumFontSize;
  margin: 0;
  padding: 2px;

  &__option {
    align-items: center;
    border-radius: $cardBorderRadius;
    color: var(--text-color);
    cursor: pointer;
    display: flex;
    height: 38px;
    padding: 0 $spacing;
    margin-right: $spacing / 4;
    white-space: nowrap;

    &:last-child {
      margin-right: 0;
    }

    &:hover:not(.framed-switch__option--selected) {
      background: var(--theme-color);
      color: var(--card-background-color);
    }

    &--selected {
      background: var(--theme-color);
      color: var(--card-background-color);
      cursor: default;
    }
  }

  @include mobile {
    align-items: stretch;
    flex-direction: column;

    &__option {
      margin-right: 0;
      margin-bottom: $spacing / 4;
      text-align: center;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
