import type { DescriptiveResult } from '@/interfaces/results';

export const defaultResultData: DescriptiveResult = {
    n: 0,
    validN: 0,
    top_n: 0,
    mid_n: 0,
    low_n: 0,
    distribution: {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
    },
    avg: 0,
    std: 0,
    stdSample: 0,
    top_n_percent: 0,
    mid_n_percent: 0,
    low_n_percent: 0,
    '1_n_percent': 0,
    '2_n_percent': 0,
    '3_n_percent': 0,
    '4_n_percent': 0,
    '5_n_percent': 0,
    median: 0,
};

export const defaultResult = {
    v_1: defaultResultData,
};

export const resultData1: DescriptiveResult = {
    n: 12,
    validN: 12,
    top_n: 0,
    mid_n: 0,
    low_n: 0,
    distribution: {
        1: 4,
        2: 4,
        3: 0,
        4: 3,
        5: 1,
    },
    avg: 2.4166666666666665,
    std: 1.3819269959814167,
    stdSample: 1.4433756729740645,
    top_n_percent: 0,
    mid_n_percent: 0,
    low_n_percent: 0,
    '1_n_percent': 33.33333333333333,
    '2_n_percent': 33.33333333333333,
    '3_n_percent': 0,
    '4_n_percent': 25,
    '5_n_percent': 8.333333333333332,
    median: 2,
};

export const result1 = {
    v_1: resultData1,
};

export const resultRaw1 = {
    "v_51": [
        "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested.",
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr. \r\n\r\nDuis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat.",
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    ]
};

export const resultsResponse = {
    status: 200,
    data: result1,
}

export const resultsAggregatedResponse = {
    status: 200,
    data: resultData1,
}

export const resultsRawResponse = {
    status: 200,
    data: resultRaw1,
}
