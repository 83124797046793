export type LocalizedPhrases = {
    [key: string]: string; // locale pointing to phrase
};

export type VariableCode = string | number;

export const VariableType = {
    nominal: 'nominal',
    numericScale: 'numericScale',
    text: 'text',
    list: 'list',
    number: 'number',
    boolean: 'boolean',
} as const;

export interface GenericTemplateComponentDimension {
    validCodes: VariableCode[];
    varname: string;
    externalVarname?: string;
    type: typeof VariableType;
    maxValue?: number; // extra for number dimensions without a scale
    unit?: string; // extra for number dimensions without a scale
    topCodes: VariableCode[];
    midCodes: VariableCode[];
    lowCodes: VariableCode[];
    label?: LocalizedPhrases;
}

export type FiltersObject = {
    [key: string]: any | any[];
}

export const LevelOfMeasurement = {
    none: 'none',
    ordinal: 'ordinal',
    nominal: 'nominal',
    ratio: 'ratio',
    interval: 'interval',
} as const;

export interface GenericTemplateComponent {
    id: string;
    title: LocalizedPhrases;
    codeLabels?: { [key: string]: LocalizedPhrases };
    height: string;
    colors: string[];
    // ui grid columns: 12
    w: number; // grid width of the component
    h: number; // grid height of the component
    x: number; // grid position of the left side of the component
    y: number; // grid position of the upper side of the component
    type: string; // UI component type
    showDimensionAverage?: boolean;
    sortDimensions?: { by: string; dir: 'ASC' | 'DESC' }; // e.g. by: 'result.avg', 'dimension.label'
    onClick?: {
        type: 'route';
        target: string; // target page name
    },
    levelOfMeasurement?: typeof LevelOfMeasurement;
    dimensions?: GenericTemplateComponentDimension[];
    filters?: FiltersObject;
    [key: string]: any;
}

export interface GenericTemplatePage {
    title?: string;
    components: GenericTemplateComponent[];
}

export interface GenericTemplatePages {
    [key: string]: GenericTemplatePage;
}

export const TemplateType = {
    default: 'default',
    aggregated: 'aggregated',
    generic: 'generic',
    all: 'all',
} as const;

export const InstanceType = {
    question: 'question',
    category: 'category',
    multiRater: 'multiRater',
    vibe: 'vibe',
    aggregated: 'aggregated',
    generic: 'generic',
} as const;

interface ConfigurationGroup {
    readonly groupId: string;
    readonly groupLabel: string;
    readonly configurations: any[];
    readonly adminData?: boolean;
}

export type GenericFilter = {
    validCodes: VariableCode[];
    varname: string;
    externalVarname?: string;
    label?: LocalizedPhrases;
    codeLabels?: { [key: string]: LocalizedPhrases };
}

export interface GenericHeader {
    id: string;
    type: string; // UI component type: FilterHeaderComponent
}

export interface GenericTemplate {
    id: string;
    type: string;
    instanceType: string;
    name: string;
    pages: GenericTemplatePages;
    configurationGroups: ConfigurationGroup[];
    filters?: GenericFilter[];
    headers?: GenericHeader[];
}

export interface GenericTemplateResponse {
    status: number;
    data: GenericTemplate;
}

export const FilterWidgetType = {
    collapsable: 'FilterGeneric',
} as const;
