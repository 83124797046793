import defaultTheme from '../constants/externalThemes';

export const backgroundColor: string = '#eff3f6';
export const neutralChartColor: string = defaultTheme.basic.primaryShade.lighten1;
export const themeColor: string = defaultTheme.basic.primary.base;
export const highScoreColor: string = defaultTheme.basic.primaryShade.darken3;
export const lowScoreColor: string = defaultTheme.basic.primaryShade.lighten3;

export const dummyInstanceId: string = 'dummy-scored-questions';
export const dummyInstanceIdWithSplit: string = 'dummy-scored-questions-with-split';
export const dummyInstanceIdWithFilter: string = 'dummy-scored-questions-with-filter';
export const dummyInstanceIdFavorable: string = 'dummy-scored-questions-favorable';
export const dummyInstanceIdMultiCategory: string = 'dummy-multi-category';
export const dummyInstanceIdMultiCategoryFavorable: string = 'dummy-multi-category-favorable';
export const dummyInstanceIdRaterGroups: string = 'dummy-rater-groups';
export const dummyInstanceIdVibes: string = 'dummy-vibes';
export const dummyInstanceIdAggregated: string = 'dummy-aggregated';
export const dummyInstanceIdGeneric: string = 'dummy-generic';
export const dummyInstanceIds: string[] = [
    dummyInstanceId,
    dummyInstanceIdWithSplit,
    dummyInstanceIdWithFilter,
    dummyInstanceIdFavorable,
    dummyInstanceIdMultiCategory,
    dummyInstanceIdMultiCategoryFavorable,
    dummyInstanceIdRaterGroups,
    dummyInstanceIdVibes,
    dummyInstanceIdAggregated,
    dummyInstanceIdGeneric,
];
export const instanceId: string = 'AAAAAAAA-BBBB-CCCC-DDDD-EEEEEEEEEEEE';
export const waveId: string = '31';
export const guideId: string = '41';
export const localeString = 'en_US';

export const templateId: string = 'E0BAB2EF-14F4-4A09-964F-6558DC1CD875';
export const templateIdMultiCategory: string = '284B5916-2B82-46A8-9E7B-270CC913605A';
export const templateIdRaterGroups: string = 'A4Ac5677-8B70-424A-ACf7-6C1ED24135D8';
export const templateIdVibes: string = '87D0E892-8998-4450-8D6C-EAC483CA0E0D';
export const templateIdAggregated: string = 'A8114601-8BEE-42BA-B1C7-DB9B93B7159A';
export const templateIdGeneric: string = 'A8114601-8BEE-42BA-B1C7-12345';

export const refreshRate: number = 30;
export const showOverallKpi = true;
export const showResources = true;
export const anonymityThreshold = false;
export const filterVariablesArray = ['tester', 'language'];

export const construct: string = 'safety-check';
export const constructLabel: string = 'Safety Check';
export const scaleTextList: string[] = [
    'Strongly disagree', 'Disagree', 'Neither agree nor disagree', 'Agree', 'Strongly agree', 'I abstain',
];

export const externalVarname: string = 'v_1';
export const breakoutVariable: string = 'u_gender';
export const widgetFilterVariables: string[] = ['tester', 'language'];
