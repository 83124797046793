import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { basic } from '@/constants/themes/basic';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'md',
    },
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: basic,
        },
    },
});
