<template>
  <div
    class="tabs"
    data-name="tabs"
  >
    <ul class="d-flex flex-wrap align-stretch ma-0 pa-0">
      <li
        v-for="({ metaname, label }, index) in tabs"
        :key="`tab_${index}`"
        class="mr-2 mb-1 px-4 py-1"
        :class="{ 'tabs__tab-selected': (metaname === selected) }"
        :data-v-step="index === 0 ? linkTourKey : null"
        :data-name="`tabItem_${metaname}`"
        @click="emitInput(metaname)">
        {{label}}
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { TabsInterface } from '@/interfaces/components';
import { OptionItem } from '@/interfaces/visual';

@Component({})
export default class Tabs extends Vue implements TabsInterface {
    @Prop({ default: [] }) tabs!: OptionItem[];
    @Prop({ default: 0 }) selected!: string;
    @Prop({ default: null }) linkTourKey!: string;

    emitInput(value: string): void {
        if (this.selected !== value) {
            this.$emit('input', value);
        }
    }
}
</script>

<style lang="scss">
@import '@/scss/01_base/variables';

.tabs {
  ul {
    list-style: none;

    li {
      background: var(--card-background-color);
      color: var(--theme-color);
      border-radius: $cardBorderRadius;
      border: solid $cardBorderWidth var(--v-neutralShade-lighten4);
      cursor: pointer;

      &:hover,
      &.tabs__tab-selected {
        background: var(--theme-color);
        color: var(--card-background-color);
        border: solid $cardBorderWidth var(--theme-color);
      }
    }
  }
}
</style>
