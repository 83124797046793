import moment from 'moment';
import { ActionContext, Getter, Store } from 'vuex';
import { VuetifyParsedThemeItem } from 'vuetify/types/services/theme';
import { InstanceStoreInitializer, InstanceStoreState } from '@/interfaces/stores';
import { BffInstanceResponseConfigurations, BffInstanceResponseData } from '@/interfaces/bff';
import { getInstanceData } from '@/services/instanceService';
import { AnonymityError } from '@/helpers/error';
import {
    defaultAnonymityThreshold,
    defaultAnonymityThresholdActivated,
    defaultBackgroundColor,
    defaultHighScoreColor,
    defaultHistoricalTrend,
    defaultLocale,
    defaultMeasure,
    defaultNeutralChartColor,
    defaultRefreshRate,
    defaultShowOverallKpi,
    defaultShowResources,
    defaultLowScoreColor,
    defaultThemeColor,
    measureMean,
    templateIds,
    instanceTypes,
    defaultThemeName,
} from '@/constants';
import i18nLocalization from '@/i18n/i18nLocalization';

import extThemes from '@/constants/externalThemes';

import Vuetify from '../plugins/vuetify';

export const getDefaultStateInstance = (): InstanceStoreState => ({
    instanceId: null,
    waveId: null, // for default templates instances
    guideId: null, // for aggregated templates instances

    feedbackConstruct: null, // only for DV based instances
    kpiConstruct: null, // only for DV based instances
    commentConstruct: null, // only for DV based instances
    templateId: null,
    templateType: null,
    standardLocale: defaultLocale,
    availableLocales: [],
    totalAnswered: 0,
    refreshRate: defaultRefreshRate,
    mainMeasure: defaultMeasure, // mean | percentFavorable
    showOverallKpi: defaultShowOverallKpi,
    showResources: defaultShowResources,
    historicalTrend: defaultHistoricalTrend,
    anonymityThreshold: defaultAnonymityThreshold,
    anonymityThresholdActivated: defaultAnonymityThresholdActivated,
    listFilterVariables: [],
    breakoutVariables: [],
    widgetFilterVariables: [],

    countCategories: 0,
    countCommentQuestions: 0,
    countScaleQuestions: 0,
    waveEnded: false,

    backgroundColor: defaultBackgroundColor,
    neutralChartColor: defaultNeutralChartColor,
    lowScoreColor: defaultLowScoreColor,
    highScoreColor: defaultHighScoreColor,
    themeColor: defaultThemeColor,
    themeName: defaultThemeName,

    locale: defaultLocale,

    hasImprovementBoard: false,

    globalDatasetFilterColumn: null,
    globalDatasetFilterValue: null,
    dynamicDatasetFilterColumn: null,
    dynamicDatasetFilterSource: null,
    dynamicDatasetFilterSourceField: null,

    isInitialized: false,
});

const instanceStore = {
    namespaced: true,
    state: getDefaultStateInstance(),
    getters: {
        isDVDashboard(state: InstanceStoreState): boolean {
            return !!(state.instanceId && (state.feedbackConstruct || state.kpiConstruct));
        },
        isMultiRatersTemplate(state: InstanceStoreState): boolean {
            return state.templateId === templateIds.multiRaterDV
                || state.templateId === templateIds.multiRater
                || state.templateType === instanceTypes.multiRater;
        },
        isMultiCategoryTemplate(state: InstanceStoreState): boolean {
            return state.templateId === templateIds.categoryDV
                || state.templateId === templateIds.category
                || state.templateType === instanceTypes.category;
        },
        isQuestionOnlyTemplate(state: InstanceStoreState): boolean {
            return state.templateId === templateIds.questionDV
                || state.templateId === templateIds.question
                || state.templateType === instanceTypes.question;
        },
        isAggregatedTemplate(state: InstanceStoreState): boolean {
            return state.templateId === templateIds.aggregatedDV
                || state.templateId === templateIds.aggregated
                || state.templateType === instanceTypes.aggregated;
        },
        showFilterWidget(
            state: InstanceStoreState,
            getters: Store<Getter<any, any>>,
            rootState: Record<string, any>,
        ): boolean {
            const surveyFiltersVarnames = rootState.surveyFilters.surveyFilters.map((filter) => filter.varname);
            const { listFilterVariables } = state;
            const availableFilters = surveyFiltersVarnames.filter((variable) => listFilterVariables.includes(variable));
            return availableFilters.length > 0;
        },
        showSplit(
            state: InstanceStoreState,
            getters: Store<Getter<any, any>>,
            rootState: Record<string, any>,
        ): boolean {
            const surveyFiltersVarnames = rootState.surveyFilters.surveyFilters.map((filter) => filter.varname);
            const { breakoutVariables } = state;
            const availableFilters = surveyFiltersVarnames.filter((variable) => breakoutVariables.includes(variable));
            return availableFilters.length > 0;
        },
    },
    mutations: {
        resetState(state: InstanceStoreState): void {
            Object.assign(state, getDefaultStateInstance());
        },
        initializeState(state: InstanceStoreState, initializer: InstanceStoreInitializer): void {
            state.instanceId = initializer.instanceId;
            // @todo: revert this workaround
            state.waveId = initializer.waveId ?? null;
            // state.waveId = '79';
            state.guideId = initializer.guideId ?? null;

            const { instanceData } = initializer;
            state.templateId = instanceData.templateId ?? null;
            state.standardLocale = instanceData.standardLocale;
            state.availableLocales = instanceData.availableLocales ? instanceData.availableLocales.map((bffLocale) => bffLocale.locale) : [];
            state.totalAnswered = instanceData.totalAnswered ?? 0;
            state.anonymityThreshold = instanceData.anonymityThreshold ?? defaultAnonymityThreshold;
            state.templateType = instanceData.templateType ?? null;
            if (state.templateType === instanceTypes.generic) {
                state.locale = initializer.localeFromRoute ?? state.standardLocale;
            }

            const config = initializer.instanceData.configurations;
            state.feedbackConstruct = config.feedbackConstruct ?? null;
            state.kpiConstruct = config.kpiConstruct ?? null;
            state.commentConstruct = config.commentConstruct ?? null;
            state.anonymityThresholdActivated = config.anonymityThreshold === true;
            state.refreshRate = config.refreshRate ?? defaultRefreshRate;
            state.mainMeasure = config.mainMeasure ?? measureMean;
            state.showOverallKpi = config.showOverallKpi === true;
            state.showResources = config.showResources === true;
            state.historicalTrend = config.historicalTrend === true;
            state.listFilterVariables = config.listFilterVariables ? config.listFilterVariables.split(',') : [];
            state.breakoutVariables = config.breakoutVariables ? config.breakoutVariables.split(',') : [];
            state.widgetFilterVariables = config.widgetFilterVariables ? config.widgetFilterVariables.split(',') : [];

            state.themeName = config.theme || defaultThemeName;

            if (instanceData.meta) {
                state.countCategories = instanceData.meta.countCategories ?? 0;
                state.countScaleQuestions = instanceData.meta.countScaleQuestions ?? 0;
                state.countCommentQuestions = instanceData.meta.countCommentQuestions ?? 0;
                state.waveEnded = instanceData.meta.waveEnded === true;
            } else {
                state.countCategories = 0;
                state.countScaleQuestions = 0;
                state.countCommentQuestions = 0;
                state.waveEnded = false;
            }

            state.globalDatasetFilterColumn = config.globalDatasetFilterColumn ?? null;
            state.globalDatasetFilterValue = config.globalDatasetFilterValue ?? null;
            state.dynamicDatasetFilterColumn = config.dynamicDatasetFilterColumn ?? null;
            state.dynamicDatasetFilterSource = config.dynamicDatasetFilterSource ?? null;
            state.dynamicDatasetFilterSourceField = config.dynamicDatasetFilterSourceField ?? null;

            state.isInitialized = true;
        },
        initializeLanguageByLocale(state: InstanceStoreState, locale: string | undefined): void {
            if (locale === undefined) {
                return;
            }

            if (state.availableLocales.includes(locale)) {
                i18nLocalization.locale = state.locale;
                const localeFragments: string[] = locale.split('_');
                const potentialLanguage: string = localeFragments[0].trim();
                state.locale = locale;
                i18nLocalization.locale = potentialLanguage;
                moment.locale(locale);
            }
        },
        initializeTheming(state: InstanceStoreState, instanceConfiguration: BffInstanceResponseConfigurations): void {
            const primary = Vuetify.framework.theme.themes.light.primary as VuetifyParsedThemeItem;
            const primaryShade = Vuetify.framework.theme.themes.light.primaryShade as VuetifyParsedThemeItem;
            const neutralShade = Vuetify.framework.theme.themes.light.neutralShade as VuetifyParsedThemeItem;
            const successPrimary = Vuetify.framework.theme.themes.light.successPrimary as VuetifyParsedThemeItem;
            const criticalPrimary = Vuetify.framework.theme.themes.light.criticalPrimary as VuetifyParsedThemeItem;
            const textPrimary = Vuetify.framework.theme.themes.light.textPrimary as string;

            if (
                (
                    primary
                    && primaryShade
                    && neutralShade
                    && successPrimary
                    && criticalPrimary
                    && textPrimary
                ) || instanceConfiguration
            ) {
                state.backgroundColor = defaultBackgroundColor;
                state.neutralChartColor = primaryShade.lighten1;
                state.highScoreColor = primaryShade.darken3;
                state.lowScoreColor = primaryShade.lighten3;
                state.themeColor = primary.base;

                document.documentElement.style.setProperty('--theme-color', primary.base);
                document.documentElement.style.setProperty('--theme-color-lighten', primaryShade.lighten4);
                document.documentElement.style.setProperty('--theme-color-darken', primary.darken1);
                document.documentElement.style.setProperty('--theme-color-extra-lighten', primaryShade.lighten5);

                document.documentElement.style.setProperty('--neutral-chart-color', primaryShade.lighten1);
                document.documentElement.style.setProperty('--high-chart-color', primaryShade.darken3);
                document.documentElement.style.setProperty('--low-chart-color', primaryShade.lighten3);

                document.documentElement.style.setProperty('--text-color', textPrimary);
                document.documentElement.style.setProperty('--card-text-color', textPrimary);
            }
        },
        updateVuetifyTheme(_: InstanceStoreState, themeKey: string) {
            Vuetify.framework.theme.themes.light.primary = extThemes[themeKey].primary;
            Vuetify.framework.theme.themes.light.secondary = extThemes[themeKey].secondary;

            Vuetify.framework.theme.themes.light.decorative1 = extThemes[themeKey].decorative1;
            Vuetify.framework.theme.themes.light.decorative2 = extThemes[themeKey].decorative2;

            Vuetify.framework.theme.themes.light.textPrimary = extThemes[themeKey].textPrimary;
            Vuetify.framework.theme.themes.light.textSecondary = extThemes[themeKey].textSecondary;

            Vuetify.framework.theme.themes.light.criticalPrimary = extThemes[themeKey].criticalPrimary;
            Vuetify.framework.theme.themes.light.criticalSecondary = extThemes[themeKey].criticalSecondary;

            Vuetify.framework.theme.themes.light.warningPrimary = extThemes[themeKey].warningPrimary;
            Vuetify.framework.theme.themes.light.warningSecondary = extThemes[themeKey].warningSecondary;

            Vuetify.framework.theme.themes.light.informativePrimary = extThemes[themeKey].informativePrimary;
            Vuetify.framework.theme.themes.light.informativeSecondary = extThemes[themeKey].informativeSecondary;

            Vuetify.framework.theme.themes.light.successPrimary = extThemes[themeKey].successPrimary;
            Vuetify.framework.theme.themes.light.successSecondary = extThemes[themeKey].successSecondary;

            Vuetify.framework.theme.themes.light.primaryShade = extThemes[themeKey].primaryShade;
            Vuetify.framework.theme.themes.light.neutralShade = extThemes[themeKey].neutralShade;
            Vuetify.framework.theme.themes.light.grey = extThemes[themeKey].neutralShade;
        },
        updateThemeColor(state: InstanceStoreState, themeColor: string): void {
            state.themeColor = themeColor;
        },
        setInstanceId(state: InstanceStoreState, instanceId: string): void {
            state.instanceId = instanceId;
        },
        setTemplateId(state: InstanceStoreState, templateId: string): void {
            state.templateId = templateId;
        },
        setAvailableLocales(state: InstanceStoreState, availableLocales: string[]): void {
            state.availableLocales = availableLocales;
        },
        setSetting(state: InstanceStoreState, value: { settingKey: string, settingValue: any }): void {
            const { settingKey, settingValue } = value;
            state[settingKey] = settingValue;
        },
        setHasImprovementBoard(state: InstanceStoreState, hasImprovementBoard: boolean): void {
            state.hasImprovementBoard = hasImprovementBoard;
        },
    },
    actions: {
        async getInstance(
            { dispatch }: ActionContext<InstanceStoreState, any>,
            { instanceId, waveId }: { instanceId: string, waveId: string | undefined },
        ): Promise<any> {
            return getInstanceData(instanceId, waveId)
                .then().catch((error: Error) => {
                    dispatch('errorStore/errorReceived', error, { root: true });
                });
        },
        checkAnonymity(
            { dispatch }: ActionContext<InstanceStoreState, any>,
            instanceData: BffInstanceResponseData,
        ): void {
            const { anonymityThreshold, totalAnswered } = instanceData;

            if (anonymityThreshold !== undefined && totalAnswered !== undefined && totalAnswered < anonymityThreshold) {
                const errorMsgParams = {
                    threshold: anonymityThreshold,
                    count: totalAnswered,
                };
                const errorMessage = i18nLocalization.t('anonymityErrorMsg', errorMsgParams) as string;
                const error = new AnonymityError(errorMessage);
                dispatch('errorStore/errorReceived', error, { root: true });
            }
        },
        async initializeInstance(
            {
                dispatch,
                commit,
                getters,
            }: ActionContext<InstanceStoreState, any>,
            initializer: InstanceStoreInitializer,
        ): Promise<void> {
            return new Promise<void>((resolve) => {
                const { configurations } = initializer.instanceData;
                /*  istanbul ignore else */
                if (configurations.refreshRate) {
                    commit('reload/updateAutoReload', configurations.refreshRate > 0, { root: true });
                }
                commit('initializeState', initializer);
                commit('initializeLanguageByLocale', initializer.localeFromRoute);
                if (configurations.theme) {
                    dispatch('changeTheme', configurations.theme);
                }

                if (!getters.isQuestionOnlyTemplate) {
                    dispatch('checkAnonymity', initializer.instanceData);
                }

                resolve();
            });
        },

        changeTheme({ commit }, themeKey: string): void {
            commit('updateVuetifyTheme', themeKey);
            commit('initializeTheming', null);
        },
    },
};

export default instanceStore;
