<template>
  <v-app id="app" class="tiv">
    <div class="app-container-wrapper">
      <div class="app-container" :class="{ 'container--inside-iframe': insideIframe }">
        <router-view />
      </div>
      <div class="custom-notifications-wrapper">
        <notifications group="global" position="bottom right" />
      </div>
    </div>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { inIframe } from '@/services/browserApiService';

@Component({})
export default class App extends Vue {
    clearSessionStorage(): void {
        try {
            const keys = Object.keys(localStorage);
            if (keys.length > 0) {
                keys.forEach((key) => {
                    if (key.indexOf('expandedComment_') > -1) {
                        localStorage.removeItem(key);
                    }
                });
            }
        } catch (e) {
            // Do nothing
        }
    }

    get insideIframe(): boolean {
        return inIframe();
    }

    created(): void {
        if (inIframe()) {
            document.body.style.backgroundColor = 'transparent';
        }

        this.clearSessionStorage();
    }
}
</script>

<style lang="scss">
@import "scss/index";
.info-cursor {
    cursor: help;
}
</style>
