<template>
  <v-hover v-slot="{ hover }">
    <v-card
      elevation="0"
      class="d-flex flex-wrap flex-sm-nowrap align-center normal-card"
      :class="{ 'card-hover': hover && !isNested && isCardClickable, 'not-clickable': !isCardClickable }"
      data-name="normalCard"
      @mouseover="overCockpitAction"
      @mouseout="outCockpitAction"
      @click="clickHandler"
    >
      <div class="d-flex flex-nowrap align-start flex-grow-1">
        <v-list-item-avatar
          tile
          size="80"
          :color="showSkeletons ? 'neutralShade lighten-4' : 'primary lighten-1'"
          class="align-self-center rounded-lg mr-0 ma-4 pa-4"
          data-name="normalCardIcon"
        >
          <v-icon v-text="icon" :color="showSkeletons ? 'neutralShade' : 'primary'" x-large />
        </v-list-item-avatar>
        <div
          class="mr-2 mt-2 flex-grow-1"
        >
          <v-card-title
            v-if="title"
            data-name="normalCardTitle"
          >
            <v-skeleton-loader :loading="showSkeletons" type="heading" min-width="100%">
              <span class="textPrimary--text">
                {{ title }}
              </span>
            </v-skeleton-loader>
          </v-card-title>
          <v-card-text
            class="textSecondary--text"
            data-name="normalCardText"
          >
            <v-skeleton-loader :loading="showSkeletons" type="text" min-width="100%">
              {{ teaser }}
            </v-skeleton-loader>
          </v-card-text>
        </div>
      </div>
      <div class="d-flex d-sm-none flex-break" />
      <div
        v-if="!hideBtn"
        class="flex-shrink-0 ml-auto mr-2 mb-2 mb-sm-0 align-self-center"
      >
        <v-btn
          :disabled="showSkeletons || !isDataAvailable"
          class="ma-2 btn-cockpit"
          :class="{ 'btn-cockpit-hover': hover && isCardClickable }"
          elevation="0"
          small
        >
          {{$t('more')}}
        </v-btn>
      </div>
    </v-card>
  </v-hover>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { CockpitActionInterface } from '@/interfaces/visual';
import { TranslateResult } from 'vue-i18n';

@Component({})
export default class CockpitAction extends Vue implements CockpitActionInterface {
    @Prop() icon!: string;
    @Prop() title!: string | TranslateResult;
    @Prop() teaser!: string | TranslateResult;
    @Prop({ default: false }) isDataAvailable!: boolean;
    @Prop({ default: false }) showSkeletons!: boolean;
    @Prop({ default: false }) hideBtn!: boolean;
    @Prop({ default: false }) isNested!: boolean;

    clickHandler(): void {
        if (this.isCardClickable) {
            this.$emit('click');
        }
    }

    overCockpitAction(): void {
        if (this.isCardClickable) {
            this.$emit('overCockpitAction');
        }
    }

    outCockpitAction(): void {
        if (this.isCardClickable) {
            this.$emit('outCockpitAction');
        }
    }

    get isCardClickable(): boolean {
        return this.isDataAvailable && !this.hideBtn && !this.showSkeletons;
    }
}

</script>
