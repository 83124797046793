import { ValidationError } from '@/helpers/error';
import { selectedFilterScale } from '@/interfaces/stores';
import { GenericFilterScaleListItem } from '@/interfaces/components';

export function isEmpty(value: string) {
    return !value || value.toString() === '';
}

export function validateEmpty(propertyName: string, propertyValue: string): void {
    if (isEmpty(propertyValue)) {
        throw new ValidationError(`${propertyName} is missing`);
    }
}

export function validateIsSet(propertyName: string, propertyValue: any): void {
    if (propertyValue === undefined) {
        throw new ValidationError(`${propertyName} is missing`);
    }
}

export function addFiltersToParams(selectedFilterScales: selectedFilterScale[] | GenericFilterScaleListItem[]): Record<string, string> {
    const params = {};
    const uniqueFilterVarname = new Set(selectedFilterScales.map((scale) => scale.filterVarname));
    for (const varName of uniqueFilterVarname) { // eslint-disable-line no-restricted-syntax
        const filteredEntries = selectedFilterScales
            .filter((scale) => scale.filterVarname === varName);
        params[varName] = filteredEntries.length === 1
            ? filteredEntries[0].code
            : filteredEntries.map((scale) => scale.code);
    }

    return params;
}
