import {
    InstanceType,
    TemplateType,
    GenericFilter,
    GenericTemplate,
    GenericHeader, GenericTemplateResponse,
} from '@/interfaces/genericTemplate';

export const genericFilters: GenericFilter[] = [
    {
        validCodes: [1, 2, 3],
        varname: 'u_1',
        externalVarname: 'u_1',
        label: {
            en_GB: 'Gender',
            de_DE: 'Gender de',
        },
        codeLabels: {
            1: {
                en_GB: 'Male',
                de_DE: 'Male de',
            },
            2: {
                en_GB: 'Female',
                de_DE: 'Female de',
            },
            3: {
                en_GB: 'Not available',
                de_DE: 'Not available de',
            },
        },
    },
    {
        validCodes: [1, 2],
        varname: 'u_2',
        label: {
            en_GB: 'Context variable 1',
            de_DE: 'Context variable 1 de',
        },
        codeLabels: {
            1: {
                en_GB: 'Not selected',
                de_DE: 'Not selected de',
            },
            2: {
                en_GB: 'Selected',
                de_DE: 'Selected de',
            },
        },
    },
    {
        validCodes: [1, 2, 3, 4, 5],
        varname: 'v_1',
        externalVarname: 'v_1',
        label: {
            en_GB: 'What is your favourite car brand?',
            de_DE: 'What is your favourite car brand? de',
        },
        codeLabels: {
            1: {
                en_GB: 'Mercedes',
                de_DE: 'Mercedes',
            },
            2: {
                en_GB: 'Audi',
                de_DE: 'Audi',
            },
            3: {
                en_GB: 'Renault',
                de_DE: 'Renault',
            },
            4: {
                en_GB: 'Toyota',
                de_DE: 'Toyota',
            },
            5: {
                en_GB: 'Peugeot',
                de_DE: 'Peugeot',
            },
        },
    },
    {
        validCodes: [1, 2, 3, 4, 5, 6, 7],
        varname: 'v_2',
        externalVarname: 'v_2',
        label: {
            en_GB: 'What is your favourite meal?',
            de_DE: 'What is your favourite meal? de',
        },
        codeLabels: {
            1: {
                en_GB: 'Hamburger',
                de_DE: 'Hamburger',
            },
            2: {
                en_GB: 'Pizza',
                de_DE: 'Pizza',
            },
            3: {
                en_GB: 'Taco stuff shells',
                de_DE: 'Taco stuff shells',
            },
            4: {
                en_GB: 'Garlic parmesan butter roasted potatoes',
                de_DE: 'Garlic parmesan butter roasted potatoes',
            },
            5: {
                en_GB: 'French dip sandwiches (roast in crockpot + au jus sauce = French dip)',
                de_DE: 'French dip sandwiches (roast in crockpot + au jus sauce = French dip)',
            },
            6: {
                en_GB: 'Red beans, sausage, and rice',
                de_DE: 'Red beans, sausage, and rice',
            },
            7: {
                en_GB: 'Baked honey marinated cod, black bean stuffed peppers',
                de_DE: 'Baked honey marinated cod, black bean stuffed peppers',
            },
        },
    },
];

const genericHeaders: GenericHeader[] = [
    {
        id: 'header-id1',
        type: 'FilterHeaderComponent',
    }
];

export const defaultTemplate: GenericTemplate = {
    id: 'dummy',
    type: TemplateType.generic,
    name: 'Generic Template',
    instanceType: InstanceType.generic,
    configurationGroups: [],
    pages: {
        startPage: {
            components: [],
        },
    },
}

export const template1: GenericTemplate = {
    id: 'template-id1',
    type: TemplateType.generic,
    name: 'Generic Template 1',
    instanceType: InstanceType.generic,
    configurationGroups: [],
    pages: {
        startPage: {
            components: [],
        },
    },
}

export const templateWithFilters: GenericTemplate = {
    id: 'template-id1',
    type: TemplateType.generic,
    name: 'Generic Template 1',
    instanceType: InstanceType.generic,
    configurationGroups: [],
    pages: {
        startPage: {
            components: [],
        },
    },
    filters: genericFilters,
    headers: genericHeaders,
}

export const templateResponse: GenericTemplateResponse = {
    status: 200,
    data: template1,
}
