import { getDescriptiveResults } from '@/services/resultsService';
import { ResultStoreState } from '@/interfaces/stores';
import { defaultResult } from '@/fixtures/results';

export const getDefaultStateResults = (): ResultStoreState => ({
    results: defaultResult,
});

const resultsStore = {
    namespaced: true,
    state: getDefaultStateResults(),
    getters: {},
    mutations: {
        resetState(state): void {
            Object.assign(state, getDefaultStateResults());
        },
        initializeState(state, loadedResults): void {
            state.results = loadedResults;
        },
    },
    actions: {
        async get({ dispatch, commit, rootState }, { instanceId, componentId }) {
            const selectedFilterScales = rootState.genericSurveyFilters.selectedGenericFilterScales;

            return getDescriptiveResults(instanceId, componentId, selectedFilterScales)
                .then((results) => {
                    commit('initializeState', results.data);
                }).catch((error: Error) => {
                    dispatch('errorStore/errorReceived', error, { root: true });
                });
        },
    },
};

export default resultsStore;
